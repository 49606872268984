export const searchBarContainer = {
    marginBottom: '16px',
}

export const baseIconButton = {
    ':hover': {
        color: 'primary.main',
    },
}

export const iconSearchButton = {
    ...baseIconButton,
    marginLeft: '16px',
}

export const iconClearButton = {
    ...baseIconButton,
    marginRight: '16px',
}
export const textFieldSearch = {
    flexGrow: 1,
    minWidth: '100px',
}
