import IconButton from '@mui/material/IconButton'
import React from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import {
    ClickAwayListener,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material'
import { deleteSession } from '../../services/Auth'
import { useTranslation } from 'react-i18next'

const ConfigButton = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
        null
    )
    const navigate = useNavigate()
    const { t } = useTranslation()

    const open = Boolean(anchorEl)

    const handleMenuClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            setAnchorEl(open ? null : event?.currentTarget)
        },
        [open]
    )

    const logout = React.useCallback(() => {
        deleteSession()
        navigate('/login')
    }, [navigate])

    const configuration = React.useCallback(() => {
        navigate('/config')
    }, [navigate])

    const myDemands = React.useCallback(() => {
        navigate('/owned')
    }, [navigate])

    const menu = React.useMemo(() => {
        return (
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Popper id={'floatingMenu'} open={open} anchorEl={anchorEl}>
                    <Paper elevation={4}>
                        <MenuList>
                            <MenuItem onClick={myDemands}>
                                <ListItemIcon>
                                    <QuestionAnswerIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {t('components.configButton.myDemands')}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={configuration}>
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {t('components.configButton.configuration')}
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {t('components.configButton.logout')}
                                </ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </Popper>
            </ClickAwayListener>
        )
    }, [t, anchorEl, open, configuration, logout, myDemands])

    return (
        <IconButton
            color={'primary'}
            sx={{
                cursor: 'pointer',
            }}
            onClick={handleMenuClick}
        >
            <SettingsIcon style={{ fontSize: '35px' }} />
            {menu}
        </IconButton>
    )
}

export default React.memo(ConfigButton)
