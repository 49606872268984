import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileFormInput } from '../../types/auth'
import ButtonLoading from '../buttonLoading'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import AgentForm from './agentForm'
import { container } from './styles'
import PasswordForm from './passwordForm/PasswordForm'

import Link from '@mui/material/Link'
type ComponentProps = {
    onSubmit(data: ProfileFormInput): void
    loading: boolean
}

const ProfileForm = ({ onSubmit, loading }: ComponentProps) => {
    const { t } = useTranslation()

    const [name, setName] = React.useState<string>('')
    const [phone, setPhone] = React.useState<string>('')
    const [influenceZone, setInfluenceZone] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [repeatPassword, setRepeatPassword] = React.useState<string>('')
    const [acceptedTerms, setAcceptedTerms] = React.useState<boolean>(false)
    const [invalidPasswords, setInvalidPasswords] =
        React.useState<boolean>(false)

    const validFields = React.useMemo(() => {
        return (
            !!name &&
            !!phone &&
            !!influenceZone &&
            !!password &&
            !!repeatPassword &&
            acceptedTerms
        )
    }, [name, phone, influenceZone, password, repeatPassword, acceptedTerms])

    const submit = React.useCallback(() => {
        if (password !== repeatPassword) {
            setInvalidPasswords(true)
        } else {
            setInvalidPasswords(false)
            onSubmit({
                name,
                phone,
                influenceZone,
                password,
                acceptedTerms,
            })
        }
    }, [
        name,
        phone,
        influenceZone,
        password,
        repeatPassword,
        acceptedTerms,
        onSubmit,
    ])

    return (
        <Box sx={container}>
            <AgentForm
                name={name}
                phone={phone}
                influenceZone={influenceZone}
                setName={setName}
                setPhone={setPhone}
                setInfluenceZone={setInfluenceZone}
            />
            <PasswordForm
                password={password}
                setPassword={setPassword}
                repeatPassword={repeatPassword}
                setRepeatPassword={setRepeatPassword}
                invalidPasswords={invalidPasswords}
            />

            <Typography
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Checkbox
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms((accepted) => !accepted)}
                />
                {t('components.profileForm.checkboxLicenseTerms')}
                <Link
                    href="/privacy-policy"
                    target="_blank"
                    sx={{ marginLeft: 1, marginRight: 1 }}
                >
                    {t('components.profileForm.linkPrivacyPolicy')}
                </Link>
                {t('components.profileForm.and')}
                <Link
                    href="/usage-conditions"
                    target="_blank"
                    sx={{ marginLeft: 1 }}
                >
                    {t('components.profileForm.linkUsageConditions')}
                </Link>
            </Typography>
            <ButtonLoading
                loading={loading}
                disabled={!validFields}
                onClick={submit}
            >
                {t('screens.register.buttonSend')}
            </ButtonLoading>
        </Box>
    )
}

export default React.memo(ProfileForm)
