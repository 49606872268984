import React from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../components/Input'
import { isValidEmail } from '../../validation'
import { register } from '../../services/Auth'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonLoading from '../../components/buttonLoading'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CenteredGrid from '../../components/centeredGrid'
import { AppBarContext } from '../../context/AppBarContext'

const Register = () => {
    const { t } = useTranslation()
    const { token } = useParams()
    const navigate = useNavigate()

    const [email, setEmail] = React.useState<string>('')
    const [registerToken, setRegisterToken] = React.useState<string>(
        token || ''
    )
    const [errorInput, setErrorInput] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)

    const { setTitle, setButtons } = React.useContext(AppBarContext)

    React.useEffect(() => {
        setTitle('')
        setButtons([
            <Button
                onClick={() => navigate('/login')}
                variant="contained"
                sx={{ marginRight: '8px' }}
            >
                {t('screens.login.appBarTitle')}
            </Button>,
        ])
    }, [navigate, setTitle, setButtons, t])

    const onRegister = React.useCallback(async () => {
        if (!isValidEmail(email)) {
            setErrorInput(t('screens.register.emailError'))
            return
        }
        setLoading(true)
        try {
            const response = await register(email, registerToken)
            navigate(`/profile/${response.user?.initToken}`)
        } catch (err) {
            setErrorInput(t('screens.register.emailExistsError'))
        } finally {
            setLoading(false)
        }
    }, [t, email, navigate, registerToken])

    const validFields = React.useMemo(() => {
        return !!email && !!registerToken
    }, [email, registerToken])

    return (
        <CenteredGrid>
            <Typography>{t('screens.register.textRegister')}</Typography>
            <Input
                label={t('screens.register.inputEmail')}
                onChange={(ev) => setEmail(ev.target.value)}
                value={email}
                type={'email'}
            />
            <Input
                label={t('screens.register.inputToken')}
                onChange={(ev) => setRegisterToken(ev.target.value)}
                disabled
                value={registerToken}
                type={'text'}
            />
            {errorInput && (
                <Typography color={'error'}>{errorInput}</Typography>
            )}
            <ButtonLoading
                loading={loading}
                onClick={onRegister}
                disabled={!validFields}
            >
                {t('screens.register.buttonSend')}
            </ButtonLoading>
        </CenteredGrid>
    )
}

export default React.memo(Register)
