import React from 'react'
import LoadingScreen from '../screens/loading'

type LoadingState = {
    loading: boolean
    setLoading(loading: boolean): void
}

export const LoadingContext = React.createContext<LoadingState>({
    loading: false,
    setLoading: () => null
})

const LoadingProvider = ({ children }: { children: React.ReactNode }) => {

    const [loading, setLoading] = React.useState<boolean>(false)

    return <LoadingContext.Provider value={{ loading, setLoading}}>
        { loading ? <LoadingScreen /> : children }
    </LoadingContext.Provider>
}

export default React.memo(LoadingProvider)