import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
    const { t } = useTranslation()
    return (
        <Grid
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 3,
                    margin: 3,
                    maxWidth: '75%',
                }}
            >
                <Typography variant="h3">
                    {t('screens.privacyPolicy.title')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.description')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.description2')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.description3')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.userResponsibilitiesTitle')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.userResponsibilities1')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.userResponsibilities2')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.userResponsibilities3')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.userResponsibilities4')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.linkPolicyTitle')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.linkPolicy1')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.linkPolicy2')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.updatesTitle')}
                </Typography>
                <Typography>{t('screens.privacyPolicy.updates1')}</Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.cookiesTitle')}
                </Typography>
                <Typography>{t('screens.privacyPolicy.cookies1')}</Typography>
                <Typography>{t('screens.privacyPolicy.cookies2')}</Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.dataProtectionTitle')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection1')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection2')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection3')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection4')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection5')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection6')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection7')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection8')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection9')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection10')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection11')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection12')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.dataProtection13')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.sslCertificateTitle')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.sslCertificate1')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.sslCertificate2')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.sslCertificate3')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.propertyTitle')}
                </Typography>
                <Typography>{t('screens.privacyPolicy.property1')}</Typography>
                <Typography>{t('screens.privacyPolicy.property2')}</Typography>
                <Typography>{t('screens.privacyPolicy.property3')}</Typography>
                <Typography variant="h5">
                    {t('screens.privacyPolicy.legalActionsTitle')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.legalActions1')}
                </Typography>
                <Typography>
                    {t('screens.privacyPolicy.legalActions2')}
                </Typography>
            </Paper>
        </Grid>
    )
}

export default React.memo(PrivacyPolicy)
