import React from 'react'
import Input from '../../Input'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

export type ComponentProps = {
    disabled?: boolean
    password: string
    setPassword(password: string): void
    repeatPassword: string
    setRepeatPassword(repeatPassword: string): void
    invalidPasswords: boolean
}

const PasswordForm = ({
    password,
    setPassword,
    repeatPassword,
    setRepeatPassword,
    invalidPasswords,
    disabled,
}: ComponentProps) => {
    const { t } = useTranslation()

    return (
        <>
            <Input
                label={t('components.profileForm.inputPassword')}
                onChange={(ev) => setPassword(ev.target.value)}
                value={password}
                required
                fullWidth
                error={invalidPasswords}
                type={'password'}
                disabled={disabled}
            />
            <Input
                label={t('components.profileForm.inputRepeatPassword')}
                onChange={(ev) => setRepeatPassword(ev.target.value)}
                value={repeatPassword}
                required
                fullWidth
                error={invalidPasswords}
                type={'password'}
                disabled={disabled}
            />
            {invalidPasswords && (
                <Typography color={'error'}>
                    {t('components.profileForm.passwordError')}
                </Typography>
            )}
        </>
    )
}

export default React.memo(PasswordForm)
