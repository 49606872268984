export const absoluteBox = {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
}

export const relativeBox = {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export const card = {
    padding: '21px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '270px',
}
