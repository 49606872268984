import React from 'react'
import Input from '../../components/Input'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from '../../validation'
import { checkSession, login } from '../../services/Auth'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../../hooks/session'
import ButtonLoading from '../../components/buttonLoading'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CenteredGrid from '../../components/centeredGrid'
import { AppBarContext } from '../../context/AppBarContext'
import { resetPasswordTokenWithEmail } from '../../services/Auth'

const Login = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string>('')
    const { setTitle, setButtons } = React.useContext(AppBarContext)
    const [resetPassword, setResetPassword] = React.useState<boolean>(false)
    const [passwordResetSent, setPasswordResetSend] = React.useState<string>('')

    const { refreshSession } = useSession()

    React.useEffect(() => {
        setTitle('')
        setButtons([
            <Button
                onClick={() => navigate('/register')}
                variant="contained"
                sx={{ marginRight: '8px' }}
            >
                {t('screens.register.appBarTitle')}
            </Button>,
        ])
    }, [t, navigate, setTitle, setButtons])

    const onResetPassword = React.useCallback(async () => {
        if (!isValidEmail(email)) {
            setError(t('screens.login.emailError'))
            return
        } else {
            setLoading(true)
            try {
                await resetPasswordTokenWithEmail(email)
            } catch (err) {}
            setPasswordResetSend(t('screens.configuration.codeSent'))
            setLoading(false)
        }
    }, [email, t])

    const onLogin = React.useCallback(async () => {
        if (!isValidEmail(email)) {
            setError(t('screens.login.emailError'))
            return
        } else {
            setLoading(true)
            try {
                await login(email, password)
                refreshSession()
                await checkSession()
                setLoading(false)
                navigate('/')
            } catch (err) {
                setError(t('screens.login.errorInvalidLogin'))
                setLoading(false)
            }
        }
    }, [t, email, password, refreshSession, navigate])

    const validFields = React.useMemo(() => {
        if (resetPassword) return !!email
        return !!email && !!password
    }, [resetPassword, email, password])

    return (
        <CenteredGrid>
            {resetPassword && (
                <Typography>
                    <Link
                        onClick={() => setResetPassword(false)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {t('screens.login.backToLogin')}
                    </Link>
                </Typography>
            )}
            {resetPassword && passwordResetSent ? (
                <Typography>{passwordResetSent}</Typography>
            ) : (
                <>
                    <Typography>
                        {t('screens.login.notAMember')}{' '}
                        <Link
                            onClick={() => navigate('/register')}
                            sx={{ cursor: 'pointer' }}
                        >
                            {t('screens.login.linkRegister')}
                        </Link>
                    </Typography>
                    {!resetPassword && (
                        <Typography>
                            {t('screens.login.forgotPassword')}{' '}
                            <Link
                                onClick={() => setResetPassword(true)}
                                sx={{ cursor: 'pointer' }}
                            >
                                {t('screens.login.linkForgotPassword')}
                            </Link>
                        </Typography>
                    )}

                    <Input
                        label={t('screens.login.inputEmail')}
                        onChange={(ev) => setEmail(ev.target.value)}
                        value={email}
                        type={'email'}
                    />
                    {!resetPassword && (
                        <Input
                            label={t('screens.login.inputPassword')}
                            onChange={(ev) => setPassword(ev.target.value)}
                            value={password}
                            type="password"
                        />
                    )}
                    {error && <Typography color={'error'}>{error}</Typography>}
                    <ButtonLoading
                        loading={loading}
                        disabled={!validFields}
                        onClick={resetPassword ? onResetPassword : onLogin}
                    >
                        {t(
                            resetPassword
                                ? 'screens.login.buttonReset'
                                : 'screens.login.buttonSend'
                        )}
                    </ButtonLoading>
                </>
            )}
        </CenteredGrid>
    )
}

export default React.memo(Login)
