import React from 'react'
import Input from '../../../components/Input'
import { useTranslation } from 'react-i18next'
import { DemandType } from '../../../types/demand'
import ButtonLoading from '../../../components/buttonLoading'
import { createNewDemand } from '../../../services/Demand'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AppBarContext } from '../../../context/AppBarContext'
import PaperContainer from '../../../components/paperContainer'
import SelectDemandType from '../../../components/selectDemandType'

const NewDemand = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [demandTitle, setDemandTitle] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [type, setType] = React.useState<DemandType>(DemandType.Help)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string>('')

    const { setTitle, setButtons } = React.useContext(AppBarContext)

    React.useEffect(() => {
        setTitle(t('screens.newDemand.appBarTitle'))
    }, [t, setTitle, setButtons, navigate])

    const validFields = React.useMemo(() => {
        return !!demandTitle && !!description && !!type
    }, [demandTitle, description, type])

    const submitDemand = React.useCallback(async () => {
        try {
            setLoading(true)
            await createNewDemand({ title: demandTitle, description, type })
            setLoading(false)
            navigate('/')
        } catch (err) {
            setLoading(false)
            setError((err as Error).message)
        }
    }, [description, demandTitle, navigate, type])

    return (
        <PaperContainer>
            <>
                <Typography>
                    {t('screens.newDemand.typographyDescription')}
                </Typography>
                <SelectDemandType
                    type={type}
                    setType={(value) => setType(value as DemandType)}
                    withAllOption={false}
                    fullWidth={true}
                />
                <Input
                    label={t('screens.newDemand.inputTitle')}
                    onChange={(ev) => setDemandTitle(ev.target.value)}
                    value={demandTitle}
                    required
                    disabled={loading}
                    sx={{ minWidth: '250px' }}
                    fullWidth
                />

                <TextField
                    placeholder={t('screens.newDemand.textAreaDescription')}
                    value={description}
                    onChange={(ev) => setDescription(ev.target.value)}
                    disabled={loading}
                    sx={{ minWidth: '250px' }}
                    required
                    multiline
                    fullWidth
                    rows={6}
                />
                <Typography color={'secondary'}>
                    {t('screens.newDemand.typographyMaxDescPermited')}
                </Typography>
                {error && (
                    <Typography color={'error'} sx={{ textAlign: 'center' }}>
                        {t(`screens.newDemand.errorSend`)} {error}
                    </Typography>
                )}
                <ButtonLoading
                    loading={loading}
                    disabled={!validFields}
                    onClick={submitDemand}
                >
                    {t('screens.newDemand.buttonSend')}
                </ButtonLoading>
            </>
        </PaperContainer>
    )
}

export default React.memo(NewDemand)
