import React from 'react'
import router from './routes/Router'
import { RouterProvider } from 'react-router-dom'
import { theme } from './theme/Theme'
import { Suspense } from 'react'
import LoadingScreen from './screens/loading'
import { ThemeProvider } from '@emotion/react'

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<LoadingScreen />}>
                <RouterProvider router={router} />
            </Suspense>
        </ThemeProvider>
    )
}

export default App
