import React from 'react'
import { PopulatedAgent } from '../../../types/agent'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { accordeonContainer, contactLine, contactDetails, icon } from './styles'
import { getPhoneInInternationalFormat } from '../../../services/Agent'

export type ComponentProps = {
    agent: PopulatedAgent
}

const ContactItem = ({ agent }: ComponentProps) => {
    const avatar = React.useMemo(() => {
        return (
            <Avatar sx={{ backgroundColor: 'primary.light' }}>
                {agent?.name?.toLocaleUpperCase().charAt(0) || null}
            </Avatar>
        )
    }, [agent])

    const internationalPhone = React.useMemo(() => {
        return getPhoneInInternationalFormat(agent.phone)
    }, [agent?.phone])

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={accordeonContainer}
            >
                <CardHeader
                    avatar={avatar}
                    title={<Typography>{agent.name}</Typography>}
                    subheader={agent.influenceZone}
                />
            </AccordionSummary>
            <AccordionDetails>
                <CardContent sx={contactDetails}>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`tel:${internationalPhone}`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <Typography sx={contactLine}>
                            <LocalPhoneIcon sx={icon} />
                            {agent.name}
                        </Typography>
                    </a>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`mailto:${agent.email}`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <Typography sx={contactLine}>
                            <EmailIcon sx={icon} /> {agent.email}
                        </Typography>
                    </a>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://wa.me/${internationalPhone}`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <Typography sx={contactLine}>
                            <WhatsAppIcon sx={icon} /> {internationalPhone}
                        </Typography>
                    </a>
                </CardContent>
            </AccordionDetails>
        </Accordion>
    )
}

export default React.memo(ContactItem)
