import AgentApi from '../api/Agent'
import UserApi from '../api/User'
import { Invite, PopulatedAgent } from '../types/agent'
import { AgentInput } from '../types/auth'
import { storeSession } from './Auth'

export const updateProfile = async (profile: AgentInput) => {
    const agentApi = new AgentApi()
    const { data } = await agentApi.updateProfile(profile)
    storeSession({ agent: data })
}

export const resetPasswordToken = async (): Promise<void> => {
    const userApi = new UserApi()
    await userApi.reset()
}

export const getAgentList = async (): Promise<PopulatedAgent[]> => {
    const agentApi = new AgentApi()
    const { data } = await agentApi.agentList()
    return data
}

export const getPhoneInInternationalFormat = (phone: string): string => {
    const basicPhone = phone.replace('s', '')
    if (phone.startsWith('+')) return basicPhone
    return `+34${basicPhone}`
}

export const getInvites = async (): Promise<Invite[]> => {
    const agentApi = new AgentApi()
    const { data } = await agentApi.getInvites()
    return data
}

export const sendInvite = async (invite: Partial<Invite>): Promise<Invite> => {
    const agentApi = new AgentApi()
    const { data } = await agentApi.sendInvite(invite)
    return data
}
