import { Agent } from './agent'

export enum DemandType {
    Help = 'Help',
    Sell = 'Sell',
    Buy = 'Buy',
}

export type DemandInput = {
    title: string
    description: string
    type: DemandType
}

export type Demand = {
    id: string
    title: string
    description: string
    owner: Agent
    interestedAgents: string[]
    type: string
    createdAt: Date
    updatedAt: Date
    solved: boolean
}

export type UpdateDemandResponse = {
    id: string
}
