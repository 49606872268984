import DemandApi from '../api/demand'
import { Demand, DemandInput } from '../types/demand'

export const getDemands = async (): Promise<Demand[]> => {
    const demandApi = new DemandApi()
    const { data } = await demandApi.getDemands()
    return data
}

export const getOwnedDemands = async (): Promise<Demand[]> => {
    const demandApi = new DemandApi()
    const { data } = await demandApi.getOwnedDemands()
    return data
}

export const createNewDemand = async (
    demandInput: DemandInput
): Promise<Demand> => {
    const demandApi = new DemandApi()
    const { data } = await demandApi.newDemand(demandInput)
    return data
}

export const changeDemandStatus = async (
    demandId: string,
    solved: boolean
): Promise<void> => {
    const demandApi = new DemandApi()
    await demandApi.changeDemandStatus(demandId, solved)
}
