import {
    CachedStorageValue,
    EmptyStorageValue,
    StaleStorageValue,
} from 'axios-cache-interceptor'
import { Demand } from '../types/demand'

type CachedResponse = StaleStorageValue | CachedStorageValue | EmptyStorageValue

export const updateDemandList = (
    listDemandsCache: CachedResponse,
    updateDemandResponse: { data: Demand }
): CachedStorageValue | 'ignore' => {
    if (listDemandsCache.state !== 'cached') {
        return 'ignore'
    }
    const demandList = listDemandsCache.data.data as Demand[]
    const { id } = updateDemandResponse.data

    let updatedDemand = demandList.find((demand) => demand.id === id)
    if (!updatedDemand) demandList.push(updateDemandResponse.data)
    else {
        updatedDemand.solved = !updatedDemand.solved
    }
    return listDemandsCache
}
