import React from 'react'
import { container, spinner } from './styles'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'

const LoadingScreen = () => {
    return (
        <Box sx={container}>
            <CircularProgress sx={spinner} />
        </Box>
    )
}
export default React.memo(LoadingScreen)
