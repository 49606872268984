export const container = {
    display: 'flex',
    height: '100%',
    width: '100%',
}

export const spinner = {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
}
