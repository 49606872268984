import React from 'react'
import { DemandType } from '../../types/demand'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import HouseIcon from '@mui/icons-material/House'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import { SxProps } from '@mui/material'
import { Theme } from '@emotion/react'

export type ComponentProps = {
    setType(type: DemandType | string): void
    type: DemandType | string
    disabled?: boolean
    withAllOption: boolean
    fullWidth?: boolean
    sx?: SxProps<Theme>
}

const SelectDemandType = ({
    type,
    setType,
    disabled,
    withAllOption = false,
    fullWidth = false,
    sx,
}: ComponentProps) => {
    const { t } = useTranslation()

    const typeDescription = React.useMemo(() => {
        if (type === DemandType.Buy)
            return t('components.selectedDemandDescription.optionBuy')
        else if (type === DemandType.Sell)
            return t('components.selectedDemandDescription.optionSell')
        else if (type === DemandType.Help)
            return t('components.selectedDemandDescription.optionHelp')
        else return t('components.selectedDemandDescription.optionAll')
    }, [type, t])

    return (
        <Grid>
            <Select
                sx={sx}
                value={type}
                onChange={(ev) => setType(ev.target.value as DemandType)}
                disabled={disabled}
                fullWidth={fullWidth}
            >
                {withAllOption && (
                    <MenuItem value={'All'}>
                        <Grid
                            container
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                        >
                            <Typography>
                                {t('components.selectDemandType.optionAll')}
                            </Typography>
                        </Grid>
                    </MenuItem>
                )}
                <MenuItem value={DemandType.Buy}>
                    <Grid
                        container
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <Typography>
                            {t('components.selectDemandType.optionBuy')}
                        </Typography>
                        <AssignmentIndIcon sx={{ color: 'secondary.main' }} />
                    </Grid>
                </MenuItem>
                <MenuItem value={DemandType.Sell}>
                    <Grid
                        container
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <Typography>
                            {t('components.selectDemandType.optionSell')}
                        </Typography>
                        <HouseIcon sx={{ color: 'secondary.main' }} />
                    </Grid>
                </MenuItem>
                <MenuItem value={DemandType.Help}>
                    <Grid
                        container
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <Typography>
                            {t('components.selectDemandType.optionHelp')}
                        </Typography>
                        <HelpOutlineIcon sx={{ color: 'secondary.main' }} />
                    </Grid>
                </MenuItem>
            </Select>
            <Typography variant="body2" sx={{ marginBottom: 2 }}>
                {typeDescription}
            </Typography>
        </Grid>
    )
}

export default React.memo(SelectDemandType)
