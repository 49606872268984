export const accordeonContainer = {
    display: 'flex',
    backgroundColor: 'secondary.light',
    cursor: 'pointer',
}

export const contactLine = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}

export const contactDetails = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
}

export const icon = {
    color: 'secondary.main',
}
