import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import React from 'react'
import { absoluteBox, card, relativeBox } from './styles'

export type ComponentProps = {
    backgroundImage?: string
    children: React.ReactNode
}

const CenteredGrid = ({
    backgroundImage = '/images/background.jpg',
    children,
}: ComponentProps) => {
    const backImage = backgroundImage ? `url("${backgroundImage}")` : {}

    return (
        <Box sx={absoluteBox}>
            <Box sx={{ ...relativeBox, backgroundImage: backImage }}>
                <Card sx={card}>{children}</Card>
            </Box>
        </Box>
    )
}

export default React.memo(CenteredGrid)
