import Paper from '@mui/material/Paper'
import React from 'react'
import { container } from './styles'

export type ComponentProps = {
    children: React.ReactNode
}

const PaperContainer = ({ children }: ComponentProps) => {
    return (
        <Paper sx={container} elevation={4}>
            {children}
        </Paper>
    )
}

export default React.memo(PaperContainer)
