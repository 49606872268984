import React from 'react'

import AuthProvider from '../../context/AuthContext'
import LoadingProvider from '../../context/LoadingContext'
import AppBarProvider from '../../context/AppBarContext'
import BaseBar from '../../screens/BaseBar/BaseBar'

export type ComponentProps = {
    children: React.ReactNode
}

const Screen = ({ children }: ComponentProps) => {
    return (
        <AppBarProvider>
            <BaseBar>
                <LoadingProvider>
                    <AuthProvider>{children}</AuthProvider>
                </LoadingProvider>
            </BaseBar>
        </AppBarProvider>
    )
}

export default React.memo(Screen)
