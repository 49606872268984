import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppBarContext } from '../../../context/AppBarContext'
import DemandList from '../../../components/demandList'
import { getOwnedDemands } from '../../../services/Demand'
import Box from '@mui/material/Box'

const OwnedDemands = () => {
    const { t } = useTranslation()

    const { setTitle } = React.useContext(AppBarContext)

    React.useEffect(() => {
        setTitle(t('screens.myDemands.appBarTitle'))
        try {
            getOwnedDemands()
        } catch (err) {
            console.log(err)
        }
    }, [t, setTitle])

    return (
        <Box sx={{ padding: '24px' }}>
            <DemandList owned={true} />
        </Box>
    )
}

export default React.memo(OwnedDemands)
