import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../../hooks/session'
import { checkSession } from '../../services/Auth'
import LoadingScreen from '../../screens/loading'
import { AppBarContext } from '../../context/AppBarContext'
import ConfigButton from '../../components/configButton'

const AuthenticatedRoute = ({ children }: { children: React.ReactNode }) => {
    const { authenticated, setAuthenticated } = useSession()
    const [loading, setLoading] = React.useState<boolean>(false)
    const navigate = useNavigate()

    const { setButtons } = React.useContext(AppBarContext)

    const getProfile = React.useCallback(async () => {
        try {
            await checkSession()
            setAuthenticated(true)
        } catch (err) {
            navigate('/login')
        } finally {
            setLoading(false)
        }
    }, [navigate, setAuthenticated])

    React.useEffect(() => {
        if (authenticated) setButtons([<ConfigButton />])
    }, [authenticated, setButtons])

    React.useEffect(() => {
        if (!authenticated) {
            setLoading(true)
            getProfile()
        }
    }, [authenticated, getProfile])

    return loading ? <LoadingScreen /> : <div>{children}</div>
}

export default React.memo(AuthenticatedRoute)
