export const contentContainer = { marginBottom: '50px' }

export const tabsContainer = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50px',
    backgroundColor: '#DEDEDE',
}

export const floatingButton = {
    position: 'fixed',
    bottom: '40px',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
}
