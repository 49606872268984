import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

export type CustomInputProps = TextFieldProps & {
    error?: boolean
    containerProps?: string
}

const CustomInput = (props: CustomInputProps) => {
    const { error, containerProps, label, ...rest } = props

    return <TextField label={label} {...rest} />
}

export default React.memo(CustomInput)
