export const appBar = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: 'secondary.dark',
    maxHeight: '50px',
}

export const logo = {
    maxHeight: 34,
    marginLeft: '12px',
    marginTop: '8px',
    marginBottom: '8px',
    cursor: 'pointer',
}
