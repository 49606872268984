import React from 'react'
import { AppBarContext } from '../../context/AppBarContext'
import PaperContainer from '../../components/paperContainer'
import { getSession } from '../../services/Auth'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import AgentForm from '../../components/profileForm/agentForm'
import { buttonsContainer, container } from './styles'
import { useTranslation } from 'react-i18next'
import ButtonLoading from '../../components/buttonLoading'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import { resetPasswordToken, updateProfile } from '../../services/Agent'
import { useSession } from '../../hooks/session'
import { Typography } from '@mui/material'
import InviteForm from '../../components/inviteForm'

const Config = () => {
    const navigate = useNavigate()

    const { t } = useTranslation()

    const { refreshSession } = useSession()

    const { setTitle } = React.useContext(AppBarContext)
    const session = getSession()

    if (!session?.agent) navigate('/login')

    const [name, setName] = React.useState<string>(session?.agent?.name || '')
    const [phone, setPhone] = React.useState<string>(
        session?.agent?.phone || ''
    )
    const [influenceZone, setInfluenceZone] = React.useState<string>(
        session?.agent?.influenceZone || ''
    )
    const [emailNotifications, setEmailNotifications] = React.useState<boolean>(
        !!session?.agent?.emailNotifications
    )

    const [loadingPassword, setLoadingPassword] = React.useState<boolean>(false)
    const [requestChangePassword, setRequestChangePassword] =
        React.useState<string>('')
    const [loadingProfile, setLoadingProfile] = React.useState<boolean>(false)

    React.useEffect(() => {
        setTitle('Configuration')
    }, [setTitle])

    const changePassword = React.useCallback(async () => {
        setLoadingPassword(true)
        await resetPasswordToken()
        setLoadingPassword(false)
        setRequestChangePassword(t('screens.configuration.codeSent'))
    }, [setLoadingPassword, t])

    const saveProfile = React.useCallback(async () => {
        if (!session) return
        setLoadingProfile(true)
        const { agent } = session
        const storedAgent = pick(agent, [
            'name',
            'phone',
            'influenceZone',
            'emailNotifications',
        ])
        const newAgent = { name, phone, influenceZone, emailNotifications }
        const equals = isEqual(storedAgent, newAgent)
        if (!equals) {
            await updateProfile(newAgent)
            refreshSession()
        }

        setLoadingProfile(false)
        navigate('/')
    }, [
        name,
        navigate,
        phone,
        influenceZone,
        session,
        refreshSession,
        emailNotifications,
    ])

    return (
        <>
            <PaperContainer>
                <ButtonLoading
                    loading={loadingPassword}
                    onClick={changePassword}
                    containerProps={buttonsContainer}
                >
                    {t('screens.configuration.changePassword')}
                </ButtonLoading>
                <Box sx={container}>
                    {requestChangePassword && (
                        <Typography
                            sx={{ marginBottom: '12px' }}
                            color={'primary.main'}
                        >
                            {requestChangePassword}
                        </Typography>
                    )}
                    <AgentForm
                        name={name}
                        phone={phone}
                        influenceZone={influenceZone}
                        setName={setName}
                        setPhone={setPhone}
                        setInfluenceZone={setInfluenceZone}
                        emailNotifications={emailNotifications}
                        setEmailNotifications={setEmailNotifications}
                        disabled={loadingPassword || loadingProfile}
                    />
                    <ButtonLoading
                        loading={loadingProfile}
                        onClick={saveProfile}
                    >
                        {t('screens.configuration.save')}
                    </ButtonLoading>
                </Box>
            </PaperContainer>
            <PaperContainer>
                <InviteForm />
            </PaperContainer>
        </>
    )
}

export default React.memo(Config)
