import React from 'react'
import Input from '../../Input'
import { useTranslation } from 'react-i18next'
import { Grid, Switch, Typography } from '@mui/material'

export type ComponentProps = {
    disabled?: boolean
    name: string
    setName(name: string): void
    phone: string
    setPhone(phne: string): void
    influenceZone: string
    setInfluenceZone(influenceZone: string): void
    emailNotifications?: boolean
    setEmailNotifications?(emailNotifications: boolean): void
}

const ALLOWED_PHONE_CHARS = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    '*',
    '#',
    '+',
]

const AgentForm = ({
    name,
    setName,
    phone,
    setPhone,
    influenceZone,
    setInfluenceZone,
    disabled,
    emailNotifications,
    setEmailNotifications,
}: ComponentProps) => {
    const { t } = useTranslation()

    const isPhoneAllowedNumber = React.useCallback((lastChar: string) => {
        return ALLOWED_PHONE_CHARS.includes(lastChar)
    }, [])

    const updatePhone = React.useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const input = ev.target.value
            if (input === '') setPhone(input)
            else {
                const lastChar = ev.target.value.charAt(input.length - 1)
                if (isPhoneAllowedNumber(lastChar)) setPhone(ev.target.value)
            }
        },
        [setPhone, isPhoneAllowedNumber]
    )

    return (
        <>
            <Input
                label={t('components.profileForm.inputName')}
                onChange={(ev) => setName(ev.target.value)}
                value={name}
                required
                fullWidth
                disabled={disabled}
            />
            <Input
                label={t('components.profileForm.inputPhone')}
                onChange={updatePhone}
                value={phone}
                type={'phone'}
                required
                fullWidth
                disabled={disabled}
            />
            <Input
                label={t('components.profileForm.inputInfluenceZone')}
                onChange={(ev) => setInfluenceZone(ev.target.value)}
                value={influenceZone}
                required
                fullWidth
                disabled={disabled}
            />
            {setEmailNotifications && (
                <Grid>
                    <Typography>
                        Recibir notificaciones por correo electrónico{' '}
                        <Switch
                            checked={emailNotifications}
                            onClick={() =>
                                setEmailNotifications(!emailNotifications)
                            }
                        />
                    </Typography>{' '}
                </Grid>
            )}
        </>
    )
}

export default React.memo(AgentForm)
