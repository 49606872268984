import React from 'react'
import { PopulatedAgent } from '../../types/agent'
import { getAgentList } from '../../services/Agent'
import Grid from '@mui/material/Grid'
import ContactItem from './contactItem'
import LoadingScreen from '../../screens/loading'
import { Typography } from '@mui/material'
import SearchBar from '../searchBar'
import { useTranslation } from 'react-i18next'

const ContactList = () => {
    const { t } = useTranslation()

    const [agents, setAgents] = React.useState<PopulatedAgent[]>([])
    const [filteredAgents, setFilteredAgents] = React.useState<
        PopulatedAgent[]
    >([])
    const [error, setError] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)

    const [search, setSearch] = React.useState<string>('')

    const fetchAgents = React.useCallback(async () => {
        setLoading(true)
        try {
            const agents = await getAgentList()
            setAgents(agents)
            setFilteredAgents(agents)
            setError('')
        } catch (err) {
            setError('Error when retrieving the agent list.')
        } finally {
            setLoading(false)
        }
    }, [])

    const doSearch = React.useCallback(() => {
        setLoading(true)
        if (!search) setFilteredAgents(agents)
        const filtered = agents.filter(
            (agent) =>
                agent.name.toLowerCase().includes(search.toLowerCase()) ||
                (agent?.influenceZone || '')
                    .toLocaleLowerCase()
                    .includes(search.toLowerCase())
        )
        setFilteredAgents(filtered)
        setLoading(false)
    }, [agents, search, setFilteredAgents, setLoading])

    const removeSearch = React.useCallback(() => {
        setLoading(true)
        setSearch('')
        setFilteredAgents(agents)
        setLoading(false)
    }, [setLoading, agents, setFilteredAgents])

    React.useEffect(() => {
        fetchAgents()
    }, [fetchAgents])

    return loading ? (
        <LoadingScreen />
    ) : error ? (
        <Typography color={'error'}>{error}</Typography>
    ) : (
        <Grid>
            <SearchBar
                searchValue={search}
                setSearchValue={setSearch}
                doSearch={doSearch}
                removeSearch={removeSearch}
                placeholderText={t('screens.home.contacts.placeholderSearch')}
            />
            <Grid container spacing={2}>
                {filteredAgents.length === 0 ? (
                    <Grid item>
                        <Typography>
                            {t('screens.home.contacts.noResults')}
                        </Typography>
                    </Grid>
                ) : (
                    filteredAgents.map((agent) => (
                        <Grid
                            item
                            key={`agentItem-${agent.id}`}
                            xs={12}
                            sm={6}
                            lg={4}
                        >
                            <ContactItem agent={agent} />
                        </Grid>
                    ))
                )}
            </Grid>
        </Grid>
    )
}

export default React.memo(ContactList)
