import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import {
    iconClearButton,
    iconSearchButton,
    searchBarContainer,
    textFieldSearch,
} from './styles'
import { useTranslation } from 'react-i18next'

export type ComponentProps = {
    searchValue: string
    setSearchValue(value: string): void
    doSearch(): void
    removeSearch(): void
    placeholderText?: string
}

const SearchBar = ({
    searchValue,
    setSearchValue,
    doSearch,
    removeSearch,
    placeholderText,
}: ComponentProps) => {
    const { t } = useTranslation()

    return (
        <Grid
            sx={searchBarContainer}
            container
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexWrap={'nowrap'}
        >
            <TextField
                sx={textFieldSearch}
                value={searchValue}
                onChange={(ev) => setSearchValue(ev.target.value)}
                placeholder={
                    placeholderText || t('components.searchBar.placeholderText')
                }
            ></TextField>
            <IconButton sx={iconSearchButton} onClick={doSearch}>
                <SearchIcon />
            </IconButton>
            <IconButton sx={iconClearButton} onClick={removeSearch}>
                <ClearIcon />
            </IconButton>
        </Grid>
    )
}

export default React.memo(SearchBar)
