import React from 'react'
import { getDemands, getOwnedDemands } from '../../services/Demand'
import { Demand, DemandType } from '../../types/demand'
import LoadingScreen from '../../screens/loading'
import DemandItem from './demandItem'
import { useNavigate } from 'react-router'
import Grid from '@mui/material/Grid'
import SearchBar from '../searchBar'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import SelectDemandType from '../selectDemandType'
import { selectSearch } from './styles'
export type ComponentProps = {
    owned: boolean
}

const DemandList = ({ owned = false }) => {
    const [loading, setLoading] = React.useState<boolean>()
    const [demands, setDemands] = React.useState<Demand[]>([])
    const [search, setSearch] = React.useState<string>('')
    const [typedDemands, setTypedDemands] = React.useState<Demand[]>([])
    const [filteredDemands, setFilteredDemands] = React.useState<Demand[]>([])
    const [selectedFilter, setSelectedFilter] = React.useState<string>('All')

    const navigate = useNavigate()
    const { t } = useTranslation()

    const getDemandsList = React.useCallback(async () => {
        try {
            setLoading(true)
            const demands = await getDemands()
            const toSetUp = demands.filter((demand) => !demand.solved)
            setDemands(toSetUp)
            setTypedDemands(toSetUp)
            setFilteredDemands(toSetUp)
            setLoading(false)
        } catch (err) {
            navigate('/login')
        }
    }, [navigate])

    const getOwnedDemandsList = React.useCallback(async () => {
        try {
            setLoading(true)
            const demands = await getOwnedDemands()
            setDemands(demands)
            setTypedDemands(demands)
            setFilteredDemands(demands)
            setLoading(false)
        } catch (err) {
            navigate('/login')
        }
    }, [navigate])

    const applySearch = React.useCallback(
        (toFilter: Demand[]) => {
            setLoading(true)
            if (!search) setFilteredDemands(typedDemands)
            const filtered = toFilter.filter(
                (demand) =>
                    demand.title.toLowerCase().includes(search.toLowerCase()) ||
                    demand.description
                        .toLowerCase()
                        .includes(search.toLowerCase())
            )
            setFilteredDemands(filtered)
            setLoading(false)
        },
        [search, typedDemands, setFilteredDemands]
    )

    const doSearch = React.useCallback(() => {
        applySearch(typedDemands)
    }, [typedDemands, applySearch])

    const handleMenuChange = React.useCallback(
        (selection: DemandType | string) => {
            setSelectedFilter(selection)
            let typed = demands
            if (selection !== 'All') {
                typed = demands.filter((demand) => {
                    return demand.type === selection
                })
            }
            setTypedDemands(typed)
            applySearch(typed)
        },
        [setTypedDemands, setSelectedFilter, applySearch, demands]
    )

    const removeSearch = React.useCallback(() => {
        setLoading(true)
        setSearch('')
        setFilteredDemands(typedDemands)
        setLoading(false)
    }, [setLoading, typedDemands, setFilteredDemands])

    React.useEffect(() => {
        if (owned) {
            getOwnedDemandsList()
        } else {
            getDemandsList()
        }
    }, [getDemandsList, getOwnedDemandsList, owned])

    return loading ? (
        <LoadingScreen />
    ) : (
        <Grid>
            <SelectDemandType
                type={selectedFilter}
                setType={handleMenuChange}
                withAllOption={true}
                fullWidth={true}
                sx={selectSearch}
            />
            <SearchBar
                searchValue={search}
                setSearchValue={setSearch}
                doSearch={doSearch}
                removeSearch={removeSearch}
                placeholderText={t('screens.home.demands.placeholderSearch')}
            />
            <Grid container spacing={2}>
                {filteredDemands.length === 0 ? (
                    <Grid item>
                        <Typography>
                            {t('screens.home.demands.noResults')}
                        </Typography>
                    </Grid>
                ) : (
                    filteredDemands.map((demand) => (
                        <Grid
                            item
                            key={`demand-${demand.id}`}
                            xs={12}
                            sm={6}
                            md={4}
                        >
                            <DemandItem demand={demand} owned={owned} />
                        </Grid>
                    ))
                )}
            </Grid>
        </Grid>
    )
}

export default React.memo(DemandList)
