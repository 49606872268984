import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UsageConditions = () => {
    const { t } = useTranslation()

    return (
        <Grid
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 3,
                    margin: 3,
                    maxWidth: '75%',
                }}
            >
                <Typography variant="h3">
                    {t('screens.usageConditions.title')}
                </Typography>
                <Typography sx={{}}>
                    <i>{t('screens.usageConditions.dateEntry')}</i>
                </Typography>
                <Typography>
                    {t('screens.usageConditions.introduction')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.usageConditions.point1Title')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point11Text')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.usageConditions.point2Title')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point21Text')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.usageConditions.point3Title')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point31Text')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point32Text')}
                </Typography>
                <Typography variant="body2">
                    {t('screens.usageConditions.point32TextSearches')}
                </Typography>
                <Typography variant="body2">
                    {t('screens.usageConditions.point32TextOffers')}
                </Typography>
                <Typography variant="body2">
                    {t('screens.usageConditions.point32TextRequests')}
                </Typography>
                <Typography variant="h5">
                    {t('screens.usageConditions.point4Title')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point41Text')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point41Option1')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point41Option2')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point41Option3')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point41Option4')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point41Option5')}
                </Typography>
                <Typography>
                    {t('screens.usageConditions.point41Option6')}
                </Typography>
                <Typography>
                    <i>{t('screens.usageConditions.dateLastUpdate')}</i>
                </Typography>
                <Typography>{t('screens.usageConditions.textEnd')}</Typography>
            </Paper>
        </Grid>
    )
}

export default React.memo(UsageConditions)
