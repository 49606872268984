import { createBrowserRouter } from 'react-router-dom'
import Register from '../screens/register'
import Login from '../screens/login'
import Home from '../screens/home'
import AuthenticatedRoute from './authenticatedRoute'
import Profile from '../screens/profile'
import NewDemand from '../screens/demand/new'
import Screen from '../screens/screen'
import Config from '../screens/config'
import OwnedDemands from '../screens/demand/owned'
import ResetPassword from '../screens/resetPassword'
import PrivacyPolicy from '../screens/privacyPolicy'
import UsageConditions from '../screens/usageConditions'

const router = createBrowserRouter([
    {
        path: '/login',
        element: (
            <Screen>
                <Login />
            </Screen>
        ),
    },
    {
        path: '/register',
        element: (
            <Screen>
                <Register />
            </Screen>
        ),
    },
    {
        path: '/register/:token',
        element: (
            <Screen>
                <Register />
            </Screen>
        ),
    },
    {
        path: '/profile/:initToken',
        element: (
            <Screen>
                <Profile />
            </Screen>
        ),
    },
    {
        path: '/reset/:initToken',
        element: (
            <Screen>
                <ResetPassword />
            </Screen>
        ),
    },
    {
        path: '/privacy-policy',
        element: (
            <Screen>
                <PrivacyPolicy />
            </Screen>
        ),
    },
    {
        path: '/usage-conditions',
        element: (
            <Screen>
                <UsageConditions />
            </Screen>
        ),
    },
    {
        path: '/',
        element: (
            <Screen>
                <AuthenticatedRoute>
                    <Home />
                </AuthenticatedRoute>
            </Screen>
        ),
    },
    {
        path: '/demand/new',
        element: (
            <Screen>
                <AuthenticatedRoute>
                    <NewDemand />
                </AuthenticatedRoute>
            </Screen>
        ),
    },
    {
        path: '/config',
        element: (
            <Screen>
                <AuthenticatedRoute>
                    <Config />
                </AuthenticatedRoute>
            </Screen>
        ),
    },
    {
        path: '/owned',
        element: (
            <Screen>
                <AuthenticatedRoute>
                    <OwnedDemands />
                </AuthenticatedRoute>
            </Screen>
        ),
    },
])

export default router
