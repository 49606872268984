import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { initProfile, verifyInitToken } from '../../services/Auth'
import ProfileForm from '../../components/profileForm/ProfileForm'
import { ProfileFormInput } from '../../types/auth'
import { useLoading } from '../../hooks/loading'
import Typography from '@mui/material/Typography'
import { AppBarContext } from '../../context/AppBarContext'
import { useTranslation } from 'react-i18next'
import PaperContainer from '../../components/paperContainer'

const Profile = () => {
    const { initToken } = useParams()
    const navigate = useNavigate()
    const { loading, setLoading } = useLoading()
    const { t } = useTranslation()

    const [initTokenVerified, setInitTokenVerified] =
        React.useState<boolean>(false)
    const [loadingProfile, setLoadingProfile] = React.useState<boolean>(false)
    const [profileUserId, setProfileUserId] = React.useState<string>('')

    const { setTitle, setButtons } = React.useContext(AppBarContext)

    React.useEffect(() => {
        setTitle('')
        setButtons([])
    }, [t, navigate, setTitle, setButtons])

    const onSumbit = React.useCallback(
        async (params: ProfileFormInput) => {
            setLoadingProfile(true)
            try {
                if (!initToken)
                    throw new Error(
                        'Init token must be present before sending the request'
                    )
                const { agent } = await initProfile(initToken, params)
                if (agent?.user !== profileUserId)
                    throw new Error(`The user id doesn't match`)
                navigate('/')
            } catch (err) {
                navigate('/login')
            } finally {
                setLoadingProfile(false)
            }
        },
        [navigate, initToken, profileUserId]
    )

    const verifyToken = React.useCallback(async () => {
        try {
            if (initToken) {
                const userId = await verifyInitToken(initToken)
                setLoading(false)
                setProfileUserId(userId)
                setInitTokenVerified(true)
            } else {
                throw new Error('Init token is not present in the url')
            }
        } catch (_) {
            navigate('/login')
        }
    }, [initToken, navigate, setLoading])

    React.useEffect(() => {
        if (!initTokenVerified && !loading) {
            verifyToken()
        }
    }, [verifyToken, initTokenVerified, loading])

    return (
        <PaperContainer>
            <>
                <Typography>{t('screens.profile.introTitle')}</Typography>
                <Typography>{t('screens.profile.introDescription')}</Typography>
                <ProfileForm loading={loadingProfile} onSubmit={onSumbit} />
            </>
        </PaperContainer>
    )
}

export default React.memo(Profile)
