import { AxiosResponse } from 'axios'
import {
    LoginResponse,
    RegisterParams,
    RegisterResponse,
    LoginParams,
    ProfileFormInput,
    Session,
    PasswordResetInput,
    PasswordResetEmailInput,
} from '../types/auth'
import { getAxios } from '.'
import BaseApi from './base'

export default class AuthApi extends BaseApi {
    async register(
        data: RegisterParams
    ): Promise<AxiosResponse<RegisterResponse>> {
        return getAxios().post<RegisterResponse>(
            '/auth/register',
            data,
            this.getConfig()
        )
    }

    async verifyInitToken(initToken: string) {
        return getAxios().get<string>(
            `/auth/init/${initToken}`,
            this.getConfig()
        )
    }

    async login(data: LoginParams): Promise<AxiosResponse<LoginResponse>> {
        return getAxios().post<LoginResponse>(
            '/auth/login',
            data,
            this.getConfig()
        )
    }

    async initProfile(initToken: string, data: ProfileFormInput) {
        return getAxios().post<Session>(
            `/auth/init/${initToken}`,
            data,
            this.getConfig()
        )
    }

    async resetPassword(input: PasswordResetInput) {
        return getAxios().post('/auth/reset', input, this.getConfig())
    }

    async resetWithEmail(
        email: PasswordResetEmailInput
    ): Promise<AxiosResponse<void>> {
        return getAxios().post('/auth/reset/email', email, this.getConfig())
    }
}
