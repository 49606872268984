import React from 'react'
import { container } from './styles'
import {
    ButtonProps,
    Box,
    CircularProgress,
    Button,
    CircularProgressProps,
    BoxProps,
} from '@mui/material'

type ComponentProps = ButtonProps & {
    containerProps?: BoxProps['sx']
    loading: boolean
    children: React.ReactNode
    spinnerProps?: CircularProgressProps['sx']
}

const ButtonLoading = ({
    children,
    loading,
    spinnerProps,
    containerProps,
    ...rest
}: ComponentProps) => {
    return (
        <Box sx={{ ...container, ...containerProps }}>
            {loading ? (
                <CircularProgress sx={spinnerProps} />
            ) : (
                <Button variant={'contained'} {...rest}>
                    {children}
                </Button>
            )}{' '}
        </Box>
    )
}

export default React.memo(ButtonLoading)
