import Axios from 'axios'
import { AxiosCacheInstance, setupCache } from 'axios-cache-interceptor/dev'

let axios: AxiosCacheInstance | null = null

export const getAxios = (): AxiosCacheInstance => {
    if (!axios) {
        axios = setupCache(Axios)
    }
    return axios
}
