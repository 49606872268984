import { parse } from 'cache-parser';
import { Header } from '../header/headers';
import { testCachePredicate } from '../util/cache-predicate';
import { updateCache } from '../util/update-cache';
import { createCacheResponse, isMethodIn } from './util';
export function defaultResponseInterceptor(axios) {
    /**
     * Rejects cache for an response response.
     *
     * Also update the waiting list for this key by rejecting it.
     */
    const rejectResponse = async (responseId, config) => {
        var _a;
        // Updates the cache to empty to prevent infinite loading state
        await axios.storage.remove(responseId, config);
        // Rejects the deferred, if present
        (_a = axios.waiting[responseId]) === null || _a === void 0 ? void 0 : _a.reject();
        delete axios.waiting[responseId];
    };
    const onFulfilled = async (response) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var _o;
        // When response.config is not present, the response is indeed a error.
        if (!response.config) {
            if (__ACI_DEV__) {
                (_a = axios.debug) === null || _a === void 0 ? void 0 : _a.call(axios, {
                    msg: 'Response interceptor received an unknown response.',
                    data: response
                });
                throw response;
            }
        }
        const id = (response.id = (_b = (_o = response.config).id) !== null && _b !== void 0 ? _b : (_o.id = axios.generateKey(response.config)));
        (_c = response.cached) !== null && _c !== void 0 ? _c : (response.cached = false);
        // Response is already cached
        if (response.cached) {
            if (__ACI_DEV__) {
                (_d = axios.debug) === null || _d === void 0 ? void 0 : _d.call(axios, {
                    id,
                    msg: 'Returned cached response'
                });
            }
            return response;
        }
        const config = response.config;
        // Request interceptor merges defaults with per request configuration
        const cacheConfig = config.cache;
        // Skip cache: either false or weird behavior
        // config.cache should always exists, at least from global config merge.
        if (!cacheConfig) {
            if (__ACI_DEV__) {
                (_e = axios.debug) === null || _e === void 0 ? void 0 : _e.call(axios, {
                    id,
                    msg: 'Response with config.cache falsy',
                    data: response
                });
            }
            return Object.assign(Object.assign({}, response), { cached: false });
        }
        // Update other entries before updating himself
        if (cacheConfig.update) {
            await updateCache(axios.storage, response, cacheConfig.update);
        }
        if (!isMethodIn(config.method, cacheConfig.methods)) {
            if (__ACI_DEV__) {
                (_f = axios.debug) === null || _f === void 0 ? void 0 : _f.call(axios, {
                    id,
                    msg: `Ignored because method (${config.method}) is not in cache.methods (${cacheConfig.methods})`,
                    data: { config, cacheConfig }
                });
            }
            return response;
        }
        const cache = await axios.storage.get(id, config);
        if (
        // If the request interceptor had a problem or it wasn't cached
        cache.state !== 'loading') {
            if (__ACI_DEV__) {
                (_g = axios.debug) === null || _g === void 0 ? void 0 : _g.call(axios, {
                    id,
                    msg: "Response not cached and storage isn't loading",
                    data: { cache, response }
                });
            }
            return response;
        }
        // Config told that this response should be cached.
        if (
        // For 'loading' values (previous: stale), this check already ran in the past.
        !cache.data &&
            !(await testCachePredicate(response, cacheConfig.cachePredicate))) {
            await rejectResponse(id, config);
            if (__ACI_DEV__) {
                (_h = axios.debug) === null || _h === void 0 ? void 0 : _h.call(axios, {
                    id,
                    msg: 'Cache predicate rejected this response'
                });
            }
            return response;
        }
        // Avoid remnant headers from remote server to break implementation
        for (const header of Object.keys(response.headers)) {
            if (header.startsWith('x-axios-cache')) {
                delete response.headers[header];
            }
        }
        if (cacheConfig.etag && cacheConfig.etag !== true) {
            response.headers[Header.XAxiosCacheEtag] = cacheConfig.etag;
        }
        if (cacheConfig.modifiedSince) {
            response.headers[Header.XAxiosCacheLastModified] =
                cacheConfig.modifiedSince === true
                    ? 'use-cache-timestamp'
                    : cacheConfig.modifiedSince.toUTCString();
        }
        let ttl = cacheConfig.ttl || -1; // always set from global config
        let staleTtl;
        if (cacheConfig.interpretHeader) {
            const expirationTime = axios.headerInterpreter(response.headers);
            // Cache should not be used
            if (expirationTime === 'dont cache') {
                await rejectResponse(id, config);
                if (__ACI_DEV__) {
                    (_j = axios.debug) === null || _j === void 0 ? void 0 : _j.call(axios, {
                        id,
                        msg: `Cache header interpreted as 'dont cache'`,
                        data: { cache, response, expirationTime }
                    });
                }
                return response;
            }
            if (expirationTime !== 'not enough headers') {
                if (typeof expirationTime === 'number') {
                    ttl = expirationTime;
                }
                else {
                    ttl = expirationTime.cache;
                    staleTtl = expirationTime.stale;
                }
            }
        }
        const data = createCacheResponse(response, cache.data);
        if (typeof ttl === 'function') {
            ttl = await ttl(response);
        }
        if (cacheConfig.staleIfError) {
            response.headers[Header.XAxiosCacheStaleIfError] = String(ttl);
        }
        if (__ACI_DEV__) {
            (_k = axios.debug) === null || _k === void 0 ? void 0 : _k.call(axios, {
                id,
                msg: 'Useful response configuration found',
                data: { cacheConfig, cacheResponse: data }
            });
        }
        const newCache = {
            state: 'cached',
            ttl,
            staleTtl,
            createdAt: Date.now(),
            data
        };
        // Resolve all other requests waiting for this response
        const waiting = axios.waiting[id];
        if (waiting) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            waiting.resolve(newCache.data);
            delete axios.waiting[id];
            if (__ACI_DEV__) {
                (_l = axios.debug) === null || _l === void 0 ? void 0 : _l.call(axios, {
                    id,
                    msg: 'Found waiting deferred(s) and resolved them'
                });
            }
        }
        // Define this key as cache on the storage
        await axios.storage.set(id, newCache, config);
        if (__ACI_DEV__) {
            (_m = axios.debug) === null || _m === void 0 ? void 0 : _m.call(axios, {
                id,
                msg: 'Response cached',
                data: { cache: newCache, response }
            });
        }
        // Return the response with cached as false, because it was not cached at all
        return response;
    };
    const onRejected = async (error) => {
        var _a, _b, _c, _d, _e, _f, _g;
        const config = error.config;
        const id = config.id;
        const cacheConfig = config.cache;
        const response = error.response;
        // config.cache should always exist, at least from global config merge.
        if (!cacheConfig || !id) {
            if (__ACI_DEV__) {
                (_a = axios.debug) === null || _a === void 0 ? void 0 : _a.call(axios, {
                    msg: 'Web request returned an error but cache handling is not enabled',
                    data: { error }
                });
            }
            throw error;
        }
        if (!isMethodIn(config.method, cacheConfig.methods)) {
            if (__ACI_DEV__) {
                (_b = axios.debug) === null || _b === void 0 ? void 0 : _b.call(axios, {
                    id,
                    msg: `Ignored because method (${config.method}) is not in cache.methods (${cacheConfig.methods})`,
                    data: { config, cacheConfig }
                });
            }
            throw error;
        }
        const cache = await axios.storage.get(id, config);
        if (
        // This will only not be loading if the interceptor broke
        cache.state !== 'loading' ||
            cache.previous !== 'stale') {
            await rejectResponse(id, config);
            if (__ACI_DEV__) {
                (_c = axios.debug) === null || _c === void 0 ? void 0 : _c.call(axios, {
                    id,
                    msg: 'Caught an error in the request interceptor',
                    data: { cache, error, config }
                });
            }
            throw error;
        }
        if (cacheConfig.staleIfError) {
            const cacheControl = String(response === null || response === void 0 ? void 0 : response.headers[Header.CacheControl]);
            const staleHeader = cacheControl && parse(cacheControl).staleIfError;
            const staleIfError = typeof cacheConfig.staleIfError === 'function'
                ? await cacheConfig.staleIfError(response, cache, error)
                : cacheConfig.staleIfError === true && staleHeader
                    ? staleHeader * 1000 //staleIfError is in seconds
                    : cacheConfig.staleIfError;
            if (__ACI_DEV__) {
                (_d = axios.debug) === null || _d === void 0 ? void 0 : _d.call(axios, {
                    id,
                    msg: 'Found cache if stale config for rejected response',
                    data: { error, config, staleIfError }
                });
            }
            if (staleIfError === true ||
                // staleIfError is the number of seconds that stale is allowed to be used
                (typeof staleIfError === 'number' && cache.createdAt + staleIfError > Date.now())) {
                // Resolve all other requests waiting for this response
                (_e = axios.waiting[id]) === null || _e === void 0 ? void 0 : _e.resolve(cache.data);
                delete axios.waiting[id];
                // re-mark the cache as stale
                await axios.storage.set(id, {
                    state: 'stale',
                    createdAt: Date.now(),
                    data: cache.data
                }, config);
                if (__ACI_DEV__) {
                    (_f = axios.debug) === null || _f === void 0 ? void 0 : _f.call(axios, {
                        id,
                        msg: 'staleIfError resolved this response with cached data',
                        data: { error, config, cache }
                    });
                }
                return {
                    cached: true,
                    config,
                    id,
                    data: cache.data.data,
                    headers: cache.data.headers,
                    status: cache.data.status,
                    statusText: cache.data.statusText
                };
            }
        }
        if (__ACI_DEV__) {
            (_g = axios.debug) === null || _g === void 0 ? void 0 : _g.call(axios, {
                id,
                msg: 'Received an unknown error that could not be handled',
                data: { error, config }
            });
        }
        throw error;
    };
    return {
        onFulfilled,
        onRejected,
        apply: () => axios.interceptors.response.use(onFulfilled, onRejected)
    };
}
