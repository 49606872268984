import { getSession } from '../services/Auth'

export default class BaseApi {
    getConfig() {
        const session = getSession()
        return {
            baseURL: process.env.REACT_APP_BACKEND_URL,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                Authorization: session?.token
                    ? `Bearer ${session.token}`
                    : null,
            },
        }
    }
}
