import { AppBar, Box, Typography } from '@mui/material'
import React from 'react'
import logo from '../../images/logo_alpha.png'
import { useTranslation } from 'react-i18next'
import { AppBarContext } from '../../context/AppBarContext'
import { appBar, logo as logoStyles } from './styles'
import { useNavigate } from 'react-router-dom'

export type ComponentProps = {
    children: React.ReactNode
}

const BaseBarScreen = ({ children }: ComponentProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { title, buttons } = React.useContext(AppBarContext)

    return (
        <>
            <AppBar position={'fixed'} sx={appBar}>
                <img
                    src={logo}
                    alt={t('components.konectasLogo.altText')}
                    style={logoStyles}
                    onClick={() => navigate('/')}
                />
                {title && <Typography variant={'h5'}>{title}</Typography>}
                <Box sx={{ marginRight: '4px' }}>
                    {buttons.length > 0 &&
                        buttons.map((button, index) => {
                            return (
                                <div key={`appBarButton-${index}`}>
                                    {button}
                                </div>
                            )
                        })}
                </Box>
            </AppBar>
            <Box
                id="contentContainer"
                sx={{
                    marginTop: '50px',
                    paddingTop: '8px',
                }}
            >
                {children}
            </Box>
        </>
    )
}

export default React.memo(BaseBarScreen)
