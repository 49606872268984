import React from 'react'
import { Demand } from '../../../types/demand'
import HouseIcon from '@mui/icons-material/House'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import Avatar from '@mui/material/Avatar'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import {
    cardHeader,
    demanTitle,
    demandButtons,
    demandTitle,
    iconButton,
    resolvedButton,
    staticIconButton,
} from './styles'
import { getPhoneInInternationalFormat } from '../../../services/Agent'
import { changeDemandStatus } from '../../../services/Demand'
import { CircularProgress } from '@mui/material'

export type ComponentProps = {
    demand: Demand
    owned: boolean
    onUpdated?(): void
}

const DemandItem = ({ demand, owned = false, onUpdated }: ComponentProps) => {
    const { t } = useTranslation()

    const [loadingChangeStatus, setLoadingChangeStatus] =
        React.useState<boolean>()

    const changeDemandStatusHandler = React.useCallback(async () => {
        try {
            setLoadingChangeStatus(true)
            await changeDemandStatus(demand.id, !demand.solved)
            onUpdated && onUpdated()
            setLoadingChangeStatus(false)
        } catch (err) {
            setLoadingChangeStatus(false)
            console.error(err)
        }
    }, [setLoadingChangeStatus, demand?.id, demand?.solved, onUpdated])

    const typeIcon = React.useMemo(() => {
        if (demand.type === 'Help') return <HelpOutlineIcon />
        else if (demand.type === 'Sell') return <HouseIcon />
        else return <AssignmentIndIcon />
    }, [demand.type])

    const publishedAt = React.useMemo(() => {
        return t('components.demandItem.publishedAt', {
            publishedAt: dayjs(demand.createdAt).format('DD/MM/YYYY'),
        })
    }, [t, demand])

    const internationalPhone = React.useMemo(() => {
        return getPhoneInInternationalFormat(demand.owner.phone)
    }, [demand.owner.phone])

    const avatar = React.useMemo(() => {
        return (
            <Avatar sx={{ bgColor: 'primary' }}>
                {demand?.owner?.name.charAt(0) || undefined}
            </Avatar>
        )
    }, [demand?.owner?.name])

    const notOwnedDemandButtons = React.useMemo(() => {
        return (
            <>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://wa.me/${internationalPhone}`}
                >
                    <Tooltip title={internationalPhone}>
                        <IconButton sx={iconButton}>
                            <WhatsAppIcon />
                        </IconButton>
                    </Tooltip>
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`mailto:${demand.owner.email}`}
                >
                    <Tooltip title={demand.owner.email}>
                        <IconButton sx={iconButton}>
                            <EmailIcon />
                        </IconButton>
                    </Tooltip>
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`tel:${internationalPhone}`}
                >
                    <Tooltip title={internationalPhone}>
                        <IconButton sx={iconButton}>
                            <CallIcon />
                        </IconButton>
                    </Tooltip>
                </a>
            </>
        )
    }, [demand?.owner?.email, internationalPhone])

    const ownedDemandButtons = React.useMemo(() => {
        return (
            <Tooltip
                title={
                    demand.solved
                        ? t('components.demandItem.resolved')
                        : t('components.demandItem.unresolved')
                }
            >
                <IconButton
                    sx={demand.solved ? resolvedButton : iconButton}
                    onClick={changeDemandStatusHandler}
                >
                    {loadingChangeStatus ? (
                        <CircularProgress size={'24px'} />
                    ) : (
                        <CheckCircleOutlineIcon />
                    )}
                </IconButton>
            </Tooltip>
        )
    }, [demand, loadingChangeStatus, changeDemandStatusHandler, t])

    return (
        <Card elevation={3}>
            <CardHeader
                avatar={avatar}
                title={
                    <Grid sx={demanTitle}>
                        <Typography sx={demandTitle}>
                            {demand?.owner?.name}
                        </Typography>
                        <Grid sx={demandButtons}>
                            {owned ? ownedDemandButtons : notOwnedDemandButtons}
                        </Grid>
                    </Grid>
                }
                subheader={demand.title}
                sx={cardHeader}
            ></CardHeader>
            <CardContent>
                <Typography variant="body2">{demand.description}</Typography>

                <CardActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 0,
                        paddingTop: '8px',
                    }}
                >
                    <>
                        <Typography variant="body2" color={'primary.dark'}>
                            {publishedAt}
                        </Typography>
                        <IconButton disabled sx={staticIconButton}>
                            {typeIcon}
                        </IconButton>
                    </>
                </CardActions>
            </CardContent>
        </Card>
    )
}

export default React.memo(DemandItem)
