import { AxiosResponse } from 'axios'
import { getAxios } from '.'
import BaseApi from './base'
import { Agent, Invite, PopulatedAgent } from '../types/agent'
import { AgentInput } from '../types/auth'

export default class AgentApi extends BaseApi {
    async me(): Promise<AxiosResponse<Agent>> {
        return getAxios().get('/api/agent/me', this.getConfig())
    }

    async updateProfile(data: AgentInput): Promise<AxiosResponse<Agent>> {
        return getAxios().post('/api/agent/me', data, this.getConfig())
    }

    async agentList(): Promise<AxiosResponse<PopulatedAgent[]>> {
        return getAxios().get('/api/agent', this.getConfig())
    }

    async getInvites(): Promise<AxiosResponse<Invite[]>> {
        return getAxios().get('/api/agent/invites', this.getConfig())
    }

    async sendInvite(invite: Partial<Invite>): Promise<AxiosResponse<Invite>> {
        return getAxios().post('/api/agent/invite', invite, this.getConfig())
    }
}
