/** Tests an response against a {@link CachePredicateObject}. */
export async function testCachePredicate(response, predicate) {
    var _a;
    if (typeof predicate === 'function') {
        return predicate(response);
    }
    const { statusCheck, responseMatch, containsHeaders } = predicate;
    if ((statusCheck && !(await statusCheck(response.status))) ||
        (responseMatch && !(await responseMatch(response)))) {
        return false;
    }
    if (containsHeaders) {
        for (const [header, predicate] of Object.entries(containsHeaders)) {
            if (!(await predicate(
            // Avoid bugs in case the header is not in lower case
            (_a = response.headers[header.toLowerCase()]) !== null && _a !== void 0 ? _a : response.headers[header]))) {
                return false;
            }
        }
    }
    return true;
}
