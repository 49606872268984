import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getInvites, sendInvite } from '../../services/Agent'
import { Invite } from '../../types/agent'
import CircularProgress from '@mui/material/CircularProgress'
import List from '@mui/material/List'
import Input from '../Input'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { first } from 'lodash'

const InviteForm = () => {
    const { t } = useTranslation()

    const [invites, setInvites] = React.useState<Invite[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)
    const [loadingError, setLoadingError] = React.useState<string>('')
    const [inviteName, setInviteName] = React.useState<string>('')
    const [inviteEmail, setInviteEmail] = React.useState<string>('')
    const [inviteError, setInviteError] = React.useState<string>('')

    const fetchInvites = React.useCallback(async () => {
        setLoading(true)
        try {
            const invites = await getInvites()
            setInvites(invites)
            setLoadingError('')
        } catch (err) {
            setLoadingError('Error when retrieving the invite list.')
        } finally {
            setLoading(false)
        }
    }, [])

    React.useEffect(() => {
        fetchInvites()
    }, [fetchInvites])

    const pendingInvites = React.useMemo(() => {
        return invites.filter((invite) => invite.status === 'AVAILABLE')
    }, [invites])

    const sentInvites = React.useMemo(() => {
        return invites.filter((invite) => invite.status === 'PENDING')
    }, [invites])

    const invite = React.useCallback(async () => {
        setLoading(true)
        if (!inviteEmail || !inviteName)
            setInviteError(t('components.inviteForm.inviteEmptyFieldError'))
        else {
            try {
                const pendingInvite = first(pendingInvites)
                const invite = await sendInvite({
                    agentName: inviteName,
                    agentEmail: inviteEmail,
                    token: pendingInvite?.token,
                })
                setInvites([
                    ...invites.filter((i) => i.token !== pendingInvite?.token),
                    invite,
                ])
                setInviteEmail('')
                setInviteName('')
                setInviteError('')
            } catch (err) {
                setInviteError(t('components.inviteForm.userAlreadyExists'))
            }
        }
        setLoading(false)
    }, [inviteEmail, inviteName, pendingInvites, t, invites])

    const inviteList = React.useMemo(() => {
        return (
            <List>
                {sentInvites.map((invite) => {
                    return (
                        <ListItem key={`invite-${invite.token}`}>
                            <ListItemText
                                primary={invite.agentName}
                                secondary={invite.agentEmail}
                            ></ListItemText>
                            <Typography>{invite.status}</Typography>
                        </ListItem>
                    )
                })}
            </List>
        )
    }, [sentInvites])

    return loading ? (
        <Grid
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress />
        </Grid>
    ) : loadingError ? (
        <Typography color="error">
            An error occurred while loading the invites
        </Typography>
    ) : (
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant={'h6'}>
                {t('screens.configuration.inviteFormTitle')}
            </Typography>
            {sentInvites.length > 0 && inviteList}

            {pendingInvites.length === 0 ? (
                <Typography>
                    {t('components.inviteForm.noTokensLeft')}
                </Typography>
            ) : (
                <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography>
                        {t('components.inviteForm.textInvite', {
                            invites: pendingInvites.length,
                        })}
                    </Typography>
                    {inviteError && (
                        <Typography color="error">{inviteError}</Typography>
                    )}
                    <Input
                        label={t('components.inviteForm.inputInviteName')}
                        onChange={(ev) => setInviteName(ev.target.value)}
                        value={inviteName}
                        required
                        fullWidth
                    />
                    <Input
                        label={t('components.inviteForm.inputInviteEmail')}
                        onChange={(ev) => setInviteEmail(ev.target.value)}
                        value={inviteEmail}
                        required
                        fullWidth
                    />
                    <Button variant="contained" onClick={invite}>
                        {t('components.inviteForm.buttonInvite')}
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default React.memo(InviteForm)
