import { Header } from '../header/headers';
/**
 * Creates a new validateStatus function that will use the one already used and also
 * accept status code 304.
 */
export function createValidateStatus(oldValidate) {
    return oldValidate
        ? (status) => oldValidate(status) || status === 304
        : (status) => (status >= 200 && status < 300) || status === 304;
}
/** Checks if the given method is in the methods array */
export function isMethodIn(requestMethod = 'get', methodList = []) {
    requestMethod = requestMethod.toLowerCase();
    return methodList.some((method) => method === requestMethod);
}
/**
 * This function updates the cache when the request is stale. So, the next request to the
 * server will be made with proper header / settings.
 */
export function updateStaleRequest(cache, config) {
    var _a;
    config.headers || (config.headers = {});
    const { etag, modifiedSince } = config.cache;
    if (etag) {
        const etagValue = etag === true ? (_a = cache.data) === null || _a === void 0 ? void 0 : _a.headers[Header.ETag] : etag;
        if (etagValue) {
            config.headers[Header.IfNoneMatch] = etagValue;
        }
    }
    if (modifiedSince) {
        config.headers[Header.IfModifiedSince] =
            modifiedSince === true
                ? // If last-modified is not present, use the createdAt timestamp
                    cache.data.headers[Header.LastModified] ||
                        new Date(cache.createdAt).toUTCString()
                : modifiedSince.toUTCString();
    }
}
/**
 * Creates the new date to the cache by the provided response. Also handles possible 304
 * Not Modified by updating response properties.
 */
export function createCacheResponse(response, previousCache) {
    if (response.status === 304 && previousCache) {
        // Set the cache information into the response object
        response.cached = true;
        response.data = previousCache.data;
        response.status = previousCache.status;
        response.statusText = previousCache.statusText;
        // Update possible new headers
        response.headers = Object.assign(Object.assign({}, previousCache.headers), response.headers);
        // return the old cache
        return previousCache;
    }
    // New Response
    return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers
    };
}
