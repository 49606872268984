import React from 'react'
import DemandList from '../../components/demandList'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import IconButton from '@mui/material/IconButton'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { AppBarContext } from '../../context/AppBarContext'
import { contentContainer, floatingButton, tabsContainer } from './styles'
import { TabPanel } from '@mui/lab'
import ContactList from '../../components/contactList'

const Home = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { setTitle, setButtons } = React.useContext(AppBarContext)

    const [tab, setTab] = React.useState<string>('1')

    const createNewDemand = React.useCallback(() => {
        navigate('/demand/new')
    }, [navigate])

    React.useEffect(() => {
        if (tab === '1') {
            setTitle(t('screens.home.demands.appBarTitle'))
        } else {
            setTitle(t('screens.home.contacts.appBarTitle'))
        }
    }, [setTitle, setButtons, t, createNewDemand, tab])

    const changeTab = React.useCallback(
        (_: React.SyntheticEvent, newValue: string) => {
            setTab(newValue)
        },
        [setTab]
    )

    const addDemandButton = React.useMemo(() => {
        return (
            <Box sx={floatingButton}>
                <IconButton
                    color={'primary'}
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={createNewDemand}
                >
                    <AddCircleIcon style={{ fontSize: '45px' }} />
                </IconButton>
            </Box>
        )
    }, [createNewDemand])

    return (
        <Box>
            <TabContext value={tab}>
                <Box sx={contentContainer}>
                    <TabPanel value="1">
                        <DemandList />
                        {addDemandButton}
                    </TabPanel>
                    <TabPanel value="2">
                        <ContactList />
                    </TabPanel>
                </Box>
                <TabPanel value="2"></TabPanel>
                <Box sx={tabsContainer}>
                    <TabList onChange={changeTab} variant={'fullWidth'}>
                        <Tab
                            label={t('screens.home.demands.appBarTitle')}
                            value="1"
                        />
                        <Tab
                            label={t('screens.home.contacts.appBarTitle')}
                            value="2"
                        />
                    </TabList>
                </Box>
            </TabContext>
        </Box>
    )
}

export default React.memo(Home)
