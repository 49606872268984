import React from 'react'
import { Session } from '../types/auth'
import { checkSession, getSession } from '../services/Auth'
import { AxiosError } from 'axios'

type Authenticating = {
    session?: Session
    authenticated: boolean
    refreshSession(): void
    setAuthenticated(authenticated: boolean): void
}

export const AuthContext = React.createContext<Authenticating>({
    authenticated: false,
    refreshSession: () => {},
    setAuthenticated: () => {},
})

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const stored = getSession()
    const [session, setSession] = React.useState<Session | undefined>(
        stored || undefined
    )
    const [authenticated, setAuthenticated] = React.useState<boolean>(false)

    const refreshSession = React.useCallback(() => {
        const storedSession = getSession()

        setSession(storedSession || undefined)
        setAuthenticated(!!storedSession?.token)
    }, [])

    const checkAuthenticated = React.useCallback(async () => {
        const storedSession = getSession()
        if (storedSession?.token) {
            if (!storedSession.agent) {
                try {
                    await checkSession()
                    refreshSession()
                } catch (err) {
                    const response = (err as AxiosError).response
                    if (
                        response?.data === 'Agent not found or not initialized'
                    ) {
                        console.log(
                            'Contact with the administrator with the code',
                            session?.token
                        )
                    }
                }
            }
        }
    }, [refreshSession, session?.token])

    React.useEffect(() => {
        checkAuthenticated()
    }, [authenticated, checkAuthenticated])

    return (
        <AuthContext.Provider
            value={{ session, authenticated, refreshSession, setAuthenticated }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
