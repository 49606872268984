export const container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
}

export const buttonsContainer = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '32px',
}
