import React from 'react'

type AppBarState = {
    title: string | React.ReactNode
    buttons: React.ReactNode[]
    setTitle(title: React.ReactNode | string): void
    setButtons(buttons: React.ReactNode[]): void
}

export const AppBarContext = React.createContext<AppBarState>({
    title: '',
    buttons: [],
    setTitle: () => {},
    setButtons: () => {},
})

const AppBarProvider = ({ children }: { children: React.ReactNode }) => {
    const [title, setTitle] = React.useState<React.ReactNode | string>('')
    const [buttons, setButtons] = React.useState<React.ReactNode[]>([])

    return (
        <AppBarContext.Provider
            value={{ title, buttons, setTitle, setButtons }}
        >
            {children}
        </AppBarContext.Provider>
    )
}

export default React.memo(AppBarProvider)
