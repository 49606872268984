import AgentApi from '../api/Agent'
import AuthApi from '../api/auth'
import {
    PasswordResetInput,
    ProfileFormInput,
    RegisterResponse,
    Session,
} from '../types/auth'

const SESSION_KEY = 'sessionData'

export const deleteSession = () => {
    sessionStorage.removeItem(SESSION_KEY)
}

export const storeSession = (sessionData: Partial<Session>) => {
    const storedJSON = sessionStorage.getItem(SESSION_KEY)
    if (!storedJSON) {
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(sessionData))
    } else {
        const stored = JSON.parse(storedJSON)
        sessionStorage.setItem(
            SESSION_KEY,
            JSON.stringify({ ...stored, ...sessionData })
        )
    }
}

export const getSession = (): Session | null => {
    const storedJSON = sessionStorage.getItem(SESSION_KEY)
    if (!storedJSON) return null
    return JSON.parse(storedJSON)
}

export const register = async (
    email: string,
    registerToken: string
): Promise<RegisterResponse> => {
    const authApi = new AuthApi()
    const response = await authApi.register({ email, password: registerToken })
    const { user } = response.data
    storeSession({ user })
    return { user }
}

export const verifyInitToken = async (initToken: string) => {
    const authApi = new AuthApi()
    const { data } = await authApi.verifyInitToken(initToken)
    return data
}

export const initProfile = async (
    initToken: string,
    parameters: ProfileFormInput
): Promise<Session> => {
    const authApi = new AuthApi()
    const { data } = await authApi.initProfile(initToken, parameters)
    storeSession(data)
    return data
}

export const login = async (email: string, password: string) => {
    const authApi = new AuthApi()
    const response = await authApi.login({ email, password })
    const { token } = response.data
    storeSession({ token })
    return token
}

export const checkSession = async () => {
    const agentApi = new AgentApi()
    const { data } = await agentApi.me()
    storeSession({ agent: data })
}

export const resetPassword = async (input: PasswordResetInput) => {
    const authApi = new AuthApi()
    await authApi.resetPassword(input)
}

export const resetPasswordTokenWithEmail = async (email: string) => {
    await new AuthApi().resetWithEmail({ email })
}
