import React from 'react'
import CenteredGrid from '../../components/centeredGrid'
import PasswordForm from '../../components/profileForm/passwordForm'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { resetPassword, verifyInitToken } from '../../services/Auth'
import LoadingScreen from '../loading'
import { PasswordResetInput } from '../../types/auth'
import { Button, Typography } from '@mui/material'

const ResetPassword = () => {
    const { t } = useTranslation()
    const { initToken } = useParams()
    const navigate = useNavigate()

    const [loadingTokenVerify, setLoadingTokenVerify] =
        React.useState<boolean>(false)
    const [password, setPassword] = React.useState<string>('')
    const [repeatPassword, setRepeatPassword] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [invalidPasswords, setInvalidpasswords] =
        React.useState<boolean>(false)
    const [userId, setUserId] = React.useState<string>('')
    const [error, setError] = React.useState<string>('')
    const [updatedText, setUpdatedText] = React.useState<string>('')

    const updatePassword = React.useCallback(async () => {
        if (password !== repeatPassword) setInvalidpasswords(true)
        else {
            setLoading(true)
            if (userId && initToken) {
                const input: PasswordResetInput = {
                    password,
                    userId,
                    initToken,
                }
                await resetPassword(input)
                setUpdatedText(t('screens.resetPassword.success'))
            } else {
                setError(`${t('screens.resetPassword.failed')} ${initToken}`)
            }
            setLoading(false)
        }
    }, [password, repeatPassword, setLoading, initToken, userId, t])

    const verifyToken = React.useCallback(async () => {
        if (!initToken) {
            setError(`${t('screens.resetPassword.failed')} ${initToken}`)
            setLoadingTokenVerify(false)
        } else {
            const userId = await verifyInitToken(initToken)
            if (!userId) navigate('/login')
            else {
                setUserId(userId)
            }
            setLoadingTokenVerify(false)
        }
    }, [navigate, initToken, setUserId, t])

    React.useEffect(() => {
        setLoadingTokenVerify(true)
        verifyToken()
    }, [initToken, verifyToken])

    return loadingTokenVerify ? (
        <LoadingScreen />
    ) : (
        <CenteredGrid>
            {error ? (
                <>
                    <Typography color="error">{error}</Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/login')}
                    >
                        Navigate to login
                    </Button>
                </>
            ) : updatedText ? (
                <>
                    <Typography color="primary.main">{updatedText}</Typography>
                    <Button variant="contained" onClick={() => navigate('/')}>
                        Ok
                    </Button>
                </>
            ) : (
                <>
                    <PasswordForm
                        password={password}
                        repeatPassword={repeatPassword}
                        setPassword={setPassword}
                        setRepeatPassword={setRepeatPassword}
                        disabled={loading}
                        invalidPasswords={invalidPasswords}
                    />
                    <LoadingButton
                        onClick={updatePassword}
                        loading={loading}
                        variant="contained"
                    >
                        {t('screens.resetPassword.send')}
                    </LoadingButton>
                </>
            )}
        </CenteredGrid>
    )
}

export default React.memo(ResetPassword)
