import { AxiosResponse } from 'axios'
import { getAxios } from '.'
import { Demand, DemandInput } from '../types/demand'
import BaseApi from './base'
import { updateDemandList } from '../cache/demand.resolver'

export default class DemandApi extends BaseApi {
    async getDemands(): Promise<AxiosResponse<Demand[]>> {
        return getAxios().get('/api/demand', {
            ...this.getConfig(),
            id: 'list-demands',
        })
    }

    async getOwnedDemands(): Promise<AxiosResponse<Demand[]>> {
        return getAxios().get('/api/demand/owned', {
            ...this.getConfig(),
            id: 'list-owned-demands',
        })
    }

    async newDemand(data: DemandInput): Promise<AxiosResponse<Demand>> {
        return getAxios().post('/api/demand', data, {
            ...this.getConfig(),
            cache: {
                update: {
                    'list-demands': 'delete',
                    'list-owned-demands': 'delete',
                },
            },
        })
    }

    async changeDemandStatus(demandId: string, solved: boolean): Promise<void> {
        return getAxios().put(
            `/api/demand/${demandId}/status`,
            { solved },
            {
                ...this.getConfig(),
                cache: {
                    update: {
                        'list-demands': 'delete',
                        'list-owned-demands': updateDemandList,
                    },
                },
            }
        )
    }
}
