import { createTheme } from '@mui/material'

export const theme = createTheme({
    palette: {
        primary: {
            light: '#e93364',
            main: '#e4013e',
            dark: '#9f002b',
        },
        secondary: {
            light: '#EFEFEF',
            main: '#979797',
            dark: '#585858',
        },
    },
})

export const themeOld = {
    fonts: {
        body: 'Open Sans, sans-serif',
        heading: 'Open Sans, sans-serif',
    },
    colors: {
        text: '#000',
        background: '#fff',
        // primary: '#e4013e',
        // primaryDark: '#9f002b',
        // primaryLight: '#e93364',
        // secondary: '#7e7e7e',
        // secondaryDark: '#585858',
        // secondaryLight: '#979797',
        error: '#E51400',
        success: '#60A917',
    },
    fontSizes: [],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    letterSpacings: {
        body: 'normal',
        caps: '0.2em',
    },
    forms: {
        input: {
            maxWidth: 300,
            borderColor: 'secondaryLight',
            '&:focus': {
                borderColor: 'secondaryLight',
                // boxShadow: (t) => `0 0 0 1px ${t?.colors?.secondaryLight}`,
                outline: 'none',
            },
        },
    },
    buttons: {
        primary: {
            '&:hover': {
                backgroundColor: 'primaryDark',
            },
            '&:disabled': {
                backgroundColor: 'secondaryLight',
            },
        },
    },
    styles: {
        root: {
            fontFamily: 'body',
            fontWeight: 'body',
            color: 'secondaryLight',
            padding: 30,
        },
    },
}
