export const demandTitle = {
    overflow: 'hidden',
}

export const cardHeader = {
    backgroundColor: 'secondary.light',
}

export const demanTitle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}

export const demandButtons = {
    display: 'flex',
}

export const iconButton = {
    color: 'secondary.main',
    ':hover': { color: 'primary.main' },
}

export const resolvedButton = {
    ...iconButton,
    color: 'green',
}

export const staticIconButton = {
    color: 'secondary.main',
    ':disabled': {
        color: 'secondary.main',
    },
}
